<template>
  <div class="container"> 
    <div class="banner">
      <div class="swiper swiper-container" style="text-align: center">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in bannerList" :key="item.id">
            <img :src="item.image" alt="" @click="openBanner(item.url)" />
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
      <!-- <el-carousel :autoplay="false" type="card" height="1.8rem">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img :src="item.image" alt="" @click="openBanner(item.url)" />
        </el-carousel-item>
      </el-carousel> -->
    </div>
    <div class="course">
      <div class="title">课程介绍</div>
      <div class="courseList">
        <div
          class="items"
          v-for="item in ipTypeList"
          :key="item.id"
          @click="openBlank('WapCourse', item.ip_type_id)"
        >
          <img :src="item.thumb" />
        </div>
      </div>
    </div>
    <div class="exam-arrangement noticeList">
      <div class="title">
        <div class="text">考试安排</div>
        <el-link
          :underline="false"
          @click="openInfo('WapInformation', 'examSchedule')"
          >更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-link>
      </div>
      <div class="newList">
        <div
          class="item"
          v-for="item in examSchedule"
          :key="item.article_id"          
        >
          <div class="newsTitle">
            <div class="text" @click="openBlank('WapDetails', item.article_id)">
              {{ item.title }}
            </div>
          </div>
          <div class="update-time">{{ item.update_time | formatDate }}</div>
        </div>
      </div>
    </div>
    <div class="project-policy noticeList">
      <div class="title">
        <div class="text">项目政策</div>
        <el-link
          :underline="false"
          @click="openInfo('WapInformation', 'projectPolicy')"
          >更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-link>
      </div>
      <div class="newList">
        <div class="item" v-for="item in projectPolicy" :key="item.article_id">
          <div class="newsTitle">
            <div class="text" @click="openBlank('WapDetails', item.article_id)">
              {{ item.title }}
            </div>
          </div>
          <div class="update-time">{{ item.update_time | formatDate }}</div>
        </div>
      </div>
    </div>
    <div class="problem-solving noticeList">
      <div class="title">
        <div class="text">问题解答</div>
        <el-link
          :underline="false"
          @click="openInfo('WapInformation', 'problemSolving')"
          >更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-link>
      </div>
      <div class="newList">
        <div class="item" v-for="item in problemSolving" :key="item.article_id">
          <div class="newsTitle">
            <div class="text" @click="openBlank('WapDetails', item.article_id)">
              {{ item.title }}
            </div>
          </div>
          <div class="update-time">{{ item.update_time | formatDate }}</div>
        </div>
      </div>
    </div>
    <div class="info-message">
      <div class="title">
        <div class="text">资讯讯息</div>
        <el-link :underline="false" @click="openBlank('WapNotice')"
          >更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-link>
      </div>
      <div class="infoList">
        <div class="item" v-for="item in newsList" :key="item.article_id">
          <img
            :src="item.thumb?item.thumb:defaultSrc"
            class="info-imgage"
            @click="openBlank('WapDetails', item.article_id)"
          />
          <div class="info-text">
            <div class="text-title">
              <div
                class="text"
                @click="openBlank('WapDetails', item.article_id)"
              >
                {{ item.title }}
              </div>
            </div>
            <div class="text-time">{{ item.update_time | formatDate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Swiper from 'swiper'
import { formatDate } from "@/utils/timeStamp.js";
export default {
  // 过滤时间
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy.MM.dd");
    },
  },
  data() {
    return {
      defaultSrc:require('@/assets/images/default.png'),  
      bannerList: [],
      ipTypeList: [],
      examSchedule: [],
      projectPolicy: [],
      problemSolving: [],
      newsList: [],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.getBannerList();
    this.getCourseImage();
    this.getNoticeList();
    this.getNewsList();
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScrollx);
  },
  methods: {   
    //banner
    getBannerList() {
      this.$http.get("/api/wap/index/getBannerList").then((res) => {
        this.bannerList = res.data.bannerList;
        this.$nextTick(function () {
          new Swiper(".swiper", {
            loop: true, // 循环模式选项
            slidesPerView: "auto",
            autoplay: {
              delay: 3000,
              disableOnInteraction: false
            },            
            observer:true,
            observeParents:true,
            
          });
        });
      });
    },
    //课程
    getCourseImage() {
      this.$http.get("/api/web/index/getIpTypeList").then((res) => {
        this.ipTypeList = res.data.ipTypeList;
      });
    },
    //通知
    getNoticeList() {
      this.$http.get("/api/wap/index/getNoticeList").then((res) => {
        const { examSchedule, projectPolicy, problemSolving } = res.data;
        this.examSchedule = examSchedule;
        this.projectPolicy = projectPolicy;
        this.problemSolving = problemSolving;
      });
    },
    //资讯
    getNewsList() {
      this.$http.get("/api/wap/index/getNewsList").then((res) => {
        this.newsList = res.data.newsList;
      });
    },
    openBlank(name, id) {
      let routeUrl = this.$router.resolve({
        name,
        query: { id },
      });
      window.open(routeUrl.href, '_self');
    },
    openInfo(name, type) {
      let routeUrl = this.$router.resolve({
        name,
        query: { type },
      });
      window.open(routeUrl.href, '_self');
    },
    openBanner(url){
      if(!!url){
        window.open(url, '_self');	
      }	else{
        return false
      }			
    },
  },
};
</script>

<style lang='less' scoped>
.container {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  /deep/.banner {
    width: 100%;
    height: auto;
    padding: 0.3rem 0 0.2rem;
    background-color: #f5f5f5;
    .swiper{
      width: 6.66rem;
      overflow: initial;
    }
    .swiper-slide{
      width: calc(90% + .3rem);
      margin: 0 .15rem;
      img{
        width: 100%;
        height: 1.91rem;
        object-fit: cover;
        object-position: center;
      }
    }
    .el-carousel__item--card {
      width: 70%;
      left: -10%;
    }
    .el-carousel__mask {
      display: none;
    }
    .el-carousel__item--card {
      img {
        display: block;
        width: 100%;
        height: 1.8rem;
        object-fit: cover;
    object-position: center;
      }
    }
  }
  .course {
    width: 100%;
    padding: 0 0.3rem 0.1rem;
    .title {
      width: 100%;
      font-size: 0.3rem;
      font-weight: 700;
      text-align: left;
      color: #333333;
      margin-bottom: 0.24rem;
    }
    .courseList {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .items {
        width: 3.3rem;
        margin-right: 0.3rem;
        img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 0.3rem;
          object-fit: cover;
          object-position: center;
        }
        &.items:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  .noticeList {
    width: calc(100% - 0.48rem);
    margin: 0 0.24rem 0.4rem;
    padding: 0.24rem;
    background-color: #fff;
    border-radius: 0.15rem;
    /deep/.title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .text {
        width: 50%;
        text-align: left;
        font-size: 0.3rem;
        font-weight: 700;
        color: #333333;
      }
      .el-link.el-link--default {
        font-size: 0.24rem;
        color: #d71917;
      }
    }
    .newList {
      width: 100%;
      margin-top: 0.24rem;
      .item {
        margin-bottom: 0.3rem;
        padding: 0.16rem 0.24rem;
        border-radius: 0.15rem;
        background-color: #f6f6f6;
        &.item:last-child {
          margin-bottom: 0;
        }
        .newsTitle {
          font-size: 0.26rem;
          line-height: 0.48rem;
          text-align: left;
          color: #333333;
          margin-bottom: 0.1rem;
          text {
            color: #333333;
          }
        }
        .update-time {
          font-size: 0.22rem;
          text-align: left;
          color: #cccccc;
        }
      }
    }
  }
  .info-message {
    width: calc(100% - 0.48rem);
    margin: 0 0.24rem;
    padding-bottom: 0.3rem;
    /deep/.title {
      display: flex;
      width: 100%;
      padding: 0 0.24rem;
      justify-content: space-between;
      .text {
        width: 50%;
        text-align: left;
        font-size: 0.3rem;
        font-weight: 700;
        color: #333333;
      }
      .el-link.el-link--default {
        font-size: 0.24rem;
        color: #d71917;
      }
    }
    .infoList {
      width: 100%;
      margin-top: 0.2rem;
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.3rem;
        padding: 0.16rem 0.24rem;
        border-radius: 0.15rem;
        background-color: #fff;
        &.item:last-child {
          margin-bottom: 0;
        }
        .info-imgage {
          width: 2.26rem;
          height: 1.6rem;
          border-radius: 0.1rem;
          margin-right: 0.34rem;
          object-fit: cover;
          object-position: center;
          overflow: hidden;
        }
        .info-text {
          width: calc(100% - 2.6rem);
          .text-title {
            font-size: 0.26rem;
            text-align: left;
            color: #333333;
            line-height: 0.48rem;
            margin-bottom: 0.24rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .text-time {
            font-size: 0.24rem;
            text-align: left;
            color: #cccccc;
          }
        }
      }
    }
  }
}
</style>
